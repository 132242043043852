/* eslint-disable */

class SzybkaWycenaExtendAction extends ValidatorForm.Action { // eslint-disable-line

	previousAction (element, event) {
		this.pageClass.closePage()
		this.pageClass.validator.previousPage(this.pageClass.sequence)
	}
}

window.SzybkaWycenaExtendAction = SzybkaWycenaExtendAction

// eslint-disable-next-line no-undef
class SzybkaWycenaEventHandlers extends ValidatorForm.EventHandlers {
	constructor (validator) {
		super()
		this.validator = validator
	}

	lockNext (elements) {
		[...elements].forEach(element => {
			element.classList.add('disabled')
		})
	}

	openPage (element) {
		this.goToTop()
		element.classList.remove('close')
		element.classList.add('open')
	}
	closePage (element) {
		element.classList.remove('open')
		element.classList.add('close')
	}
	goToTop () {
		setTimeout(() => {
			document.querySelector('.quick-quote').scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			})
		}, 10)
	}

	clearForm () {
		const inputs = [...this.validator.form.querySelectorAll('input[type=text]')]
		const checkboxes = [...this.validator.form.querySelectorAll('input[type=checkbox]')]
		const radio = [...this.validator.form.querySelectorAll('input[type=radio]')]
		const textareas = [...this.validator.form.querySelectorAll('textarea')]
		const typeTextareas = [...this.validator.form.querySelectorAll('input[type=textarea]')]
		const radioGroupBoxes = [...this.validator.form.querySelectorAll('.radio-group')]
		inputs.forEach(element => {
			if (element.type == 'hidden') return
			element.value = ''
		})
		textareas.forEach(element => {
			element.value = ''
		})
		typeTextareas.forEach(element => {
			element.value = ''
		})
		checkboxes.forEach(element => {
			element.checked = false
		})
		radio.forEach(element => {
			element.checked = false
		})
		radioGroupBoxes.forEach(element => {
			element.classList.remove('blurred')
			element.classList.remove('active')
		})
		let pages = this.validator.pages
		pages.forEach(page => {
			page.inputs.forEach(input => {
				if (input.mask) input.mask.updateValue()
				if (input.mask2) input.mask2.updateValue()
			})
		})
	}
	removeOpen () {
		this.validator.pages.forEach((item, index) => {
			item.pageElement.classList.remove('open')
			if (!item.pageElement.classList.contains('close')) item.pageElement.classList.add('close')
			if (index === 0) {
				item.pageElement.classList.remove('close')
				item.pageElement.classList.add('open')
			}
		})
	}
	asyncSuccess (url, response) {
	  window.dataLayer = window.dataLayer || [];
    dataLayer.push({'event': 'modal_form_send'});
		
		// dodajemy klase z display block na diva z thankyou page
		document.querySelector('.loading').classList.add('close')
		const thanksPage = document.querySelector('.thankspage')
		thanksPage.classList.add('endFormVisible')
		
		this.validator.eventHandlers.clearForm()
		this.removeOpen()
	}

	asyncFailed (url, response) {
	  window.dataLayer = window.dataLayer || [];
    dataLayer.push({'event': 'modal_form_error'});

		document.querySelector('.loading').classList.add('close')
		const errorPage = document.querySelector('.formerrorpage')
		errorPage.classList.add('endFormVisible')
		this.validator.eventHandlers.clearForm()

	// dodajemy klase z display block na diva z error page
	}

	submitForm () {
		// this.validator.form.querySelector('.loading').classList.remove('close')
		document.querySelector('.loading').classList.remove('close')
		return true
	}

	renderList (Page, uploader) {
		uploader.render()
		let actionBar = [...uploader.config.container.querySelectorAll('[data-action]')]
		let actionList = []
		let Action = Page.Action;
		[...actionBar].forEach(button => {
			let actionName = button.getAttribute('data-action')
			let event = button.hasAttribute('data-action-event') ? button.getAttribute('data-action-event') : 'click'
			actionList.push(new Action(actionName, Page, event))
		})
	}
}
window.SzybkaWycenaEventHandlers = SzybkaWycenaEventHandlers
